<template>
  <div class="richtext-container">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="160px"
      class="demo-ruleForm"
    >
      <el-form-item label="是否参与拼团:" prop="name">
        <el-switch
          v-model="form.is_group"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="商品名称:" prop="name">
        {{ form.name }}
      </el-form-item>

      <el-form-item label="商品封面:" prop="cover">
        <!-- <upload-one
          v-model="form.cover"
          style="width: 350px; height: 150px"
        ></upload-one> -->
        <img style="width: 350px; height: 150px" :src="form.cover" alt="" />
      </el-form-item>

      <el-form-item label="价格" prop="price">
        <!-- <el-input
          v-model="form.price"
          style="width: 350px"
          onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
          onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
        ></el-input> -->
        {{ form.product_specs_combination[0].price }}
      </el-form-item>
      <el-form-item label="拼团价" prop="group_price">
        <el-input
          v-model="form.group_price"
          style="width: 350px"
          onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
          onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
        ></el-input>
      </el-form-item>
      <el-form-item label="拼团人数" prop="group_num">
        <el-input
          v-model="form.group_num"
          style="width: 350px"
          type="number"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      prices: "",  
      id: null,
      btnLoading: false,
      form: {
        name: "",
        cover: "",
        price: "",
        group_price: "",
        group_num: "",
        is_group: 1,
      },
      rules: {
        name: [
          { required: true, message: "商品名称不能为空", trigger: "blur" },
        ],
        cover: [
          { required: true, message: "商品封面不能为空", trigger: "change" },
        ],
        // price: [{ required: true, message: "价格不能为空", trigger: "blur" }],
        group_price: [
          { required: true, message: "拼团价不能为空", trigger: "blur" },
        ],
        group_num: [
          { required: true, message: "拼团人数不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    // this.form = JSON.parse(this.$route.query.data);
    // console.log(this.form.is_group);
    this.id = this.$route.query.data;
    this.getDetail();
  },
  methods: {
    getDetail() {
      request({
        url: "/api/backend/product/detail",
        method: "get",
        params: {
          key: "park_config",
          id: this.id,
        },
      }).then((response) => {
        this.form = response.data;
        // this.form.product_specs_combination.forEach(el => {
        //      console.log(el.price);
        // });
//        最小值
        let min = 1000000;
        for (var i = 0; i < this.form.product_specs_combination.length; i++) {
            if (min > this.form.product_specs_combination[i].price) {
                min =this.form.product_specs_combination[i].price;
            }
        }
        console.log(min);
        
        this.prices = min;
        if (response.data.group_price==null) {
          this.form.is_group = 1;
        }
      });
    },
    saveData() {
      if(this.form.group_price>this.prices){
           this.$message.error("拼团价格不能高于原价");
           return;

      }  
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/product/groupStore",
            method: "post",
            data: this.form,
          })
            .then(() => {
              this.btnLoading = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.go(-1);
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.richtext-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
}
</style>
